.home-top-font1{
    font-size: 2.7rem;
    font-weight: bolder;
    color: #3388FF;
    /* padding-top: 210px;
    padding-left: 356px; */
}
.home-top-font2{
    font-size: 1rem;
    color: #666666;
    margin-bottom: 16px;
    margin-top: -5px;
    margin-left: 5px;
    /* padding-top: 210px;
    padding-left: 356px; */
}
.home-top-box1{
    padding-top: 15rem;
    padding-left: 24rem;
}
.home-top-box2{
    height: 440px;
    width: 100%;
    /* background-color: #3388FF; */
}
.home-top-box3{
    /* height: 500px; */
    /* margin: 0 auto; */
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 26px;
    margin-top: 70px;
    /* background-color: #3388FF; */
}
.top-box2-font1{
    font-size: 18px;
    color: #8A8A8A;
    text-align: center;
    margin-top: 5px;
}

.top-box2-font2{
    font-size: 26px;
    color: black;
    text-align: center;
    margin-top: 70px;
}
.top-box2-font3{
   height: 3px;
   width: 100px;
   position: absolute;
   left:40%;
   background-color: #3388FF;
}

.home-top-box4{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.home-top-font3{
    height: 322px;
    width: 260px;
    margin-left: 20px;
    margin-right: 20px;
    
}
.home-top-img1{
    height: 200px;
    width: 1027px;
}
.home-top-img3{
    height: 200px;
    width: 504px;
}
.home-top-img2{
    height: 200px;
    width: 393px;
    margin-left: 20px;
}
.top-img1-font-box{
    height: 145px;
    width: 343px;
    margin-left: 160px;
    margin-top: 23px;
}
.top-img1-font1{
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
}
.top-img1-font2{
    font-size: 22px;
    font-weight: lighter;
    color: #ffffff;
}
.top-img1-font3{
    width: 109px;
    height: 3px;
    background-color: #ffffff;
    margin-top: 25px;
    margin-bottom: 3px;
}
.top-img2-font-box{
    height: 145px;
    width: 210px;
    margin-left: 25px;
    margin-top: 47px;
}
.top-img2-font1{
    font-size: 20px;
    color: #ffffff;
    font-weight: lighter;
}
.top-img2-font2{
    font-size: 10px;
    color: #ffffff;
    margin-top: 5px;
}
.top-img2-font3{
    font-size: 7px;
    color: #ffffff;
    margin-top: 20px;
}
.top-img3-font-box{
    height: 145px;
    width: 220px;
    margin-left: 34px;
    margin-top: 64px;
}
.top-img4-font-box{
    height: 145px;
    width: 240px;
    margin-left: 241px;
    margin-top: 64px;
    text-align: right; 
}

.top-img3-font1{
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}
.top-img3-font2{
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-top: -5px;
}
.top-img3-font3{
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 3px;
}

.top-img5-font-box{
    height: 145px;
    width: 630px;
    margin-left: 54px;
    padding-top: 46px;
}
.top-img5-font1{
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
}
.top-img5-font2{
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 3px;
}
.top-img5-font3{
    font-size: 11px;
    color: #ffffff;
    font-weight: lighter;
    margin-top: 20px;
}
.top-img6-font-box{
    height: 245px;
    width: 320px;
    margin-left: 38px;
    padding-top: 105px;
}
.top-img6-font1{
    font-size:38px;
    margin-top:-5px;
    color: #ffffff;
    font-weight: bold;
    height: 70px;
    width: 96%;
    border-bottom: 3px dotted #ffffff;
}
.top-img6-font3{
    font-size: 13px;
    color: #ffffff;
    /* font-weight: lighter; */
    margin-top: 10px;
    width: 222px;
}

.top-img7-font1{
    text-align:center;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    margin-top: 33px;
}
.top-img7-font2{
    height: 18px;
    width: 287px;
    margin-left: 53px;
}
.top-img7-font3{
    margin-left: 28px;
    margin-top: 25px;
    width: 243px;
    height: 55px;
    font-size: 9px;
    color: #ffffff;
    font-weight: lighter;
}
.top-img8-left{
    height: 150px;
    width: 380px;
    margin-left: 61px;
    margin-top: 40px;
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
}
.top-img8-left-font1{
    width: 380px;
    height: 39px;
    margin-top: 8px;
    background-color: #ffffff;
    border-radius: 3px;
    text-align: center;
    color: #CD1E25;
    font-size: 15px;
    padding-top: 7px;
    /* font-weight: normal; */
}
.top-img8-right{
    width: 388px;
    height: 81px;
    font-size: 16px;
    color: #ffffff;
    margin-top: 60px;
    margin-left: 110px;
}

.top-img9-font-box{
    height: 150px;
    width: 281px;
    margin-top: 37px;
    margin-left: 109px;
    color: #ffffff;
}
.top-img9-font1{
    font-size: 38px;
    font-weight: bold;
}
.top-img9-font2{
    font-size: 12px;
    margin-top: 2px;
}

.home-bottom-box1{
    height: 400px;
    width: 340px;
    margin-right: 26px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgb(211, 211, 211),-1px -1px 2px rgb(211, 211, 211);
}
.bottom-box1-img1{
    height: 90px;
    width: 120px;
    margin-top: 96px;
    margin-left: 110px;
}
.bottom-box1-font1{
    font-size:29px;
    color: black;
    text-align: center;
    margin-top: 30px;
}
.bottom-box1-font2{
    font-size:19px;
    color: #666666;
    margin-top: 30px;
    margin-left: 35px;
    width: 280px;
    text-align: center;
}
.bottom-box1-img2{
    height: 10px;
    width: 120px;
    margin-left: 115px;
    margin-top: 40px;
}
.bottom-box1-img3{
    height: 112px;
    width: 100px;
    margin-top: 84px;
    margin-left: 120px;
    margin-bottom: -10px;
}
.bottom-box1-img4{
    height: 108px;
    width: 124px;
    margin-top: 87px;
    margin-left: 108px;
    margin-bottom: -10px;
}
.bottom-box1-img5{
    height: 92px;
    width: 80px;
    margin-top: 95px;
    margin-left: 130px;
}
.bottom-box2-font1{
    width: 1439px;
    height: 110px;
    display: flex;
    padding-top: 20px;
    border: 1px solid #ddd;
}
.bottom-box2-img1{
    height: 70px;
    width: 182px;
    margin-left: 20px;
}
.bottom-box2-img3{
    height: 70px;
    width: 190px;
    margin-left: 19px;
    border: 1px solid #ddd;
}
.bottom-box2-img2{
    height: 70px;
    width: 158px;
    margin-left: 18px;
}