

.foodSafety-box1{
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    margin-top: 100px;
}

.foodSafety-box1-font1{
    width: 22px;
    height: 3px;
    background-color: #076CE0;
    margin: 0 auto;
    margin-top: 15px;
}
.foodSafety-box1-font2{
    /* font-weight: bold; */
    font-size: 26px;
}
.foodSafety-box1-font3{
    font-size: 18px;
    color: #8A8A8A;
    font-weight: lighter;
}

.foodSafety-box2{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    font-weight: 600;
    font-size: 26px;
    margin-top: 60px;
}
.riskHandling-box2-img1{
    width: 230px;
    height: 100px;
    margin: 10px 0;
    font-size: 19px;
    font-weight: normal;
}
.campusSecurity-box2-font1{
    margin-top: 15px;
    color: #8A8A8A ;
    font-size: 17px;
}
.foodSafety-box2-font2{
    width: 760px;
    height: 800px;
    padding-top: 250px;
    margin-left: 40px;
}
.foodSafety-box2-font3{
    font-size: 20px;
    color: #8A8A8A;
    padding:65px 45px ;
    font-weight: normal;
    text-align: left;
    line-height: 36px;
}
.foodSafety-box3{
    width: 850px;
    height: 500px;
    text-align: left;
    margin-top: 30px;
}
.campusSecurity-box3-img1{
    height: 426px;
    width: 743px;
    margin-top: 90px;
}
.campusSecurity-box3-font1{
    font-size: 20px;
    color: #8A8A8A;
    font-weight: normal;
    text-align: left;
    line-height: 40px;
    width: 608px;
    margin-top: 46px;
}
.foodSafety-box3-font2{
    height: 1px;
    width: 1440px;
    margin: 0 auto;
    background-color: #cccccc;
    margin-top: -40px;
    margin-bottom: 50px;
}
.riskHandling-box3-img2{
    height: 364px;
    width: 464px;
    margin-top: -50px;
}
.riskHandling-box3-img3{
    height: 332px;
    width: 640px;
    margin-top: -50px;
    /* padding-left: 30px; */
    /* margin-left: -100px; */
}
.foodSafety-box3-img4{
    height: 413px;
    width: 480px;
    margin-top: -90px;
    /* padding-left: 30px; */

}

.drugSafety-font1{
    font-size: 18px;
    color: #8A8A8A;
    text-align: center;
    width: 1440px;
    font-weight: lighter;
}

.campusSecurity-box-left{
    margin-top: 80px;
    width: 620px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
}

.campusSecurity-box-left1{
    margin-top: 40px;
    width: 620px;
    height: 60px;
    text-align: center;
}

.campusSecurity-box-img1{
    height: 50px;
    width: 143px;
    cursor: pointer;
}
