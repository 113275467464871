.header-box {
    display: flex;
    height: 3.8vw;
    width: 100%;
    background-color: white;
    border-bottom: 2px solid #dddddd;
    position: fixed;
    z-index: 1000;
    top: 0;
}

.header-left {
    height: 100%;
    width: 30%;
    display: flex;
}

.header-right {
    height: 100%;
    /* width: 40%; */
    display: flex;
    position: absolute;
    right: 4vw;
}

.header-img {
    height: 2vw;
    /* width: 170px; */
    margin-left: 6vw;
    margin-top: 0.9vw;
}

.header-right-font2 {
    /*border: 0.1rem solid rgb(63,152,207);*/
    color: black;
    /*background-color: rgba(0,136,252,1);*/
    width: 6.5vw;
    height: 2.5vw;
    font-size: 0.9vw;
    /*margin-top: 2rem;*/
    /*margin-left: 3rem;*/
    /*padding-top: 0.8rem;*/
    /*padding-left: 1.5rem;*/
    /*border-radius: 5px;*/
    cursor: pointer;
    cursor: hand;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
}

.header-menu {
    width: 60vw;
    height: 100%;
    font-size: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    /* background-color: #2A7AFB; */
    /* margin-top: -0.2vw;
    margin-bottom: 0.9vw; */
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    /* padding: 0 1vw; */
    /* width: 200px; */
    /* margin-top: 1vw; */
}
.ant-menu-horizontal{
    line-height:3.7vw
}

.header-right-font1 {
    border-bottom: 2px solid #2A7AFB;
    color: #2A7AFB;
    /*background-color: rgba(218,235,248,1);*/
    width: 6.5vw;
    height: 2.5vw;
    font-size: 0.9vw;
    /*margin-top: 1.5rem;*/
    /*margin-left: 3rem;*/
    /*padding-top: 0.8rem;*/
    /*padding-left: 1.5rem;*/
    /*border-radius: 5px;*/
    cursor: pointer;
    cursor: hand;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    text-align: center;

}

.header-right-icon {
    /* height: 200px; */
    width: 60px;
    /* border: 1px solid #dddddd; */
    position: absolute;
    right: 40px;
    top: 281px;
    /* z-index: -1000; */
    /* bottom: 50%; */
}

.right-icon1 {
    width: 60px;
    height: 90px;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgb(211, 211, 211), -1px -1px 2px rgb(211, 211, 211);
    margin-bottom: 15px;
}

.right-icon2 {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgb(211, 211, 211), -1px -1px 2px rgb(211, 211, 211);
    margin-bottom: 15px;
}

.right-icon3 {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    /* border: 1px solid #dddddd; */
    box-shadow: 1px 1px 2px rgb(211, 211, 211), -1px -1px 2px rgb(211, 211, 211);
    /* background-color: #2A7AFB; */
    /* margin-bottom: 15px; */
    /* position: absolute;
    right: -60px;
    bottom: 100% */
}

.right-icon6 {
    width: 60px;
    height: 90px;
    margin-bottom: 15px;
}

.header-right-icon1 {
    height: 300px;
    width: 226px;
    position: absolute;
    right: 110px;
    top: 281px;
}

.ant-back-top {
    position: absolute;
    right: 20px;
    top: 288px;
}

.right-icon1-img1 {
    margin-left: 17px;
    margin-top: 17px;
    height: 180px;
    width: 180px;
    background-color: white;
}

.right-icon1-font1 {
    margin-left: -17px;
    margin-top: 10px;
    font-size: 19px;
    text-align: center;
    color: #243558;
}

.right-icon1-font2 {
    margin-left: -15px;
    margin-top: 20px;
    font-size: 17px;
    text-align: center;
    color: #7B89A6;
}

.header-right-icon2 {
    height: 240px;
    width: 226px;
    position: absolute;
    right: 110px;
    top: 281px;
    padding-top: 16px;
    padding-left: 10px;
}

.right-icon2-font1 {
    color: #7B89A6;
    font-size: 15px;
}

.right-icon2-font2 {
    color: #243558;
    font-size: 17px;
    margin-bottom: 8px;
}

.header-right-icon3 {
    height: 300px;
    width: 226px;
    position: absolute;
    right: 110px;
    top: 281px;
}

.ant-menu-vertical .ant-menu-item {
    width: 300px;
    text-align: center;
    height: 50px;
    font-size: 18px;
    padding-top: 5px;

}

/* ant-menu ant-menu-sub ant-menu-vertical{
    
} */

.ant-menu-submenu {
    padding-top: 8px;
}

.ant-menu-submenu-popup>.ant-menu {
    margin-left: -115px;
}