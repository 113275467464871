.ecochain-content {
    width: 100%;
    margin-top: 3.8vw;
    position: relative;

    .ecochain-header {
        position: absolute;
        z-index: 10000;
    }

    .ecochain-box1 {
        width: 100%;
        z-index: -100px;

        .top-black-img {
            width: 100%;
            margin-left: -1px;
        }

        .top-img1 {
            width: 6%;
            position: absolute;
            left: 4vw;
            top: 3vw;
            z-index: 10;
        }

        .top-img2 {
            width: 19%;
            position: absolute;
            left: 0.5vw;
            // bottom: 10px;
            top: 17vw;
            z-index: 10;
        }

        .top-img3 {
            width: 8%;
            position: absolute;
            right: 2.5vw;
            top: 2.3vw;
            z-index: 11;
        }

        .top-img4 {
            width: 57vw;
            position: absolute;
            right: 4vw;
            top: 2.5vw;
            z-index: 10;
        }

        .top-box1 {
            position: absolute;
            right: 2vw;
            top: 3.8vw;
            z-index: 20;
            height: 5vw;
            width: 15vw;

            // background-color: antiquewhite;
            .top-box1-div1 {
                width: 100%;
                height: 50%;
                display: flex;
                flex-direction: row;
                justify-content: right;
                align-items: center;
                font-size: 1.2vw;
                letter-spacing: 0.2vw;
                color: white;

                // font-weight: bold;
                .top-box1-font1 {
                    font-size: 1.7vw;
                    color: #F59A23;
                    margin-top: -0.6vw;
                    letter-spacing: 0.3vw;
                }

                .top-box1-font2 {
                    font-size: 1.7vw;
                    color: #95F204;
                    margin-top: -0.6vw;
                    letter-spacing: 0.3vw;
                }
            }
        }

        .top-box2 {
            position: absolute;
            left: 10vw;
            top: 13vw;
            z-index: 20;
            height: 17vw;
            width: 50vw;

            // background-color: #95F204;
            .top-box2-div1 {
                width: 100%;
                height: 4.5vw;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                font-size: 2.1vw;
                letter-spacing: 1vw;
                color: white;
                font-weight: bold;

                .top-box2-font1 {
                    color: #FFFF00;
                    font-size: 2.1vw;
                    margin-top: 0.05vw;
                }

            }

            .top-box2-div2 {
                width: 100%;
                height: 2.5vw;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                font-size: 1vw;
                letter-spacing: 0.15vw;
                color: white;
                font-weight: bold;
            }

            .top-box2-div3 {
                width: 85%;
                height: 3.6vw;
                // background-color: #95F204;
                margin-top: 3vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .div3-button {
                    width: 50%;
                    height: 82%;
                    background-color: rgba(15, 89, 164, 1);
                    border-radius: 5vw;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 1.4vw;
                    color: white;
                    font-family: '黑体';
                }

                .div3-button:hover {
                    background-color: rgb(13, 130, 248);
                }

            }

            .top-box2-div4 {
                width: 85%;
                text-align: center;
                color: white;
                font-size: 1vw;
            }
        }

        .top-box3 {
            position: absolute;
            right: 2.4vw;
            top: 25vw;
            z-index: 20;
            height: 8.7vw;
            height: 8.7vw;
            background-color: white;
        }
    }

    .ecochain-box2 {
        width: 100%;
        // height: 500px;
        background-color: white;
        padding-top: 2.4vw;

        .ecochain-box2-title {
            width: 100%;
            text-align: center;
            color: black;
            font-size: 1.3vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.1vw;
            margin-top: 2.4vw;

            // font-weight: bold;
            .box2-title-font1 {
                font-size: 1.5vw;
                color: rgba(251, 10, 10, 1);
                margin-top: -1px;
            }

            .box2-title-font2 {
                font-size: 1.5vw;
                color: rgba(10, 119, 251, 1);
                margin-top: -1px;
            }
        }

        .ecochain-box2-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;

            .box2-content-right {
                width: 39%;
                height: 22vw;
                // background-color: #95F204;
                position: relative;
                margin-left: 1.4vw;

                .right-p1 {
                    position: absolute;
                    height: 3.7vw;
                    top: 0;
                    right: 0
                }

                .right-p2 {
                    position: absolute;
                    height: 3.7vw;
                    top: 2.2vw
                }

                .right-p3 {
                    position: absolute;
                    height: 3.7vw;
                    top: 4.2vw;
                    right: 0;
                }

                .right-p4 {
                    position: absolute;
                    height: 5.1vw;
                    top: 7vw;
                    left: 2.3vw;
                }

                .right-p5 {
                    position: absolute;
                    height: 5.1vw;
                    top: 10.5vw
                }

                .right-p6 {
                    position: absolute;
                    height: 5.1vw;
                    top: 13vw;
                    right: 0;
                }

                .right-p7 {
                    position: absolute;
                    height: 3.7vw;
                    top: 17vw;
                    left: 2.3vw;
                }
            }

        }

        .content1-bottom-p3 {
            width: 100%;
            // position: absolute;
            // bottom: 0;
            margin-top: -18vw;
        }



        .ecochain-box2-content1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: end;
            width: 100%;
            margin-top: 3.3vw;
            margin-bottom: 2.3vw;
            padding-bottom: 1.4vw;


            .box2-content1-bottom {
                width: 21%;
                height: 37vw;
                margin-left: 1.4vw;
            }

            .box2-content1-bottom1 {
                width: 21%;
                height: 41.5vw;
                margin-left: 1.4vw;
            }

            .content1-bottom-p1 {
                width: 85%;
                // display: block;
                margin: 0.9vw;
                // margin: 0 auto;
            }

            .content1-bottom-font1 {
                width: 6vw;
                height: 2.3vw;
                text-align: center;
                font-size: 1.1vw;
                font-weight: bold;
                padding-top: 0.2vw;
                margin-left: 1.2vw;
            }

            .content1-bottom-box1 {
                width: 100%;
                height: 14vw;
                // display: flex;
                margin-left: 1.2vw;
                padding-top: 0.5vw;
                position: relative;

                .content1-bottom-font2 {
                    font-size: 1.4vw;
                    font-weight: bold;
                    background-image: -webkit-linear-gradient(right, rgba(64, 176, 224, 1), rgba(64, 176, 224, 1), rgba(13, 86, 147, 1));
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    letter-spacing: 0.1vw;
                }

                .content1-bottom-p2 {
                    height: 2vw;
                    position: absolute;
                    top: 0.6vw;
                    right: 3.3vw;
                }

                .content1-bottom-font3 {
                    margin-top: 0.5vw;
                    font-size: 1vw;
                    letter-spacing: 0.24vw;
                    width: 85%;
                    color: rgba(88, 88, 88, 1);
                }
            }
        }
    }

    .ecochain-box3 {
        width: 100%;
        // background-color: white;
        padding-top: 50px;
        position: relative;

        .ecochain-box2-p5 {
            width: 60vw;
            position: absolute;
            z-index: -100;
            top: 14vw;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        .box3-content1 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .box3-content1-left {
                width: 26.5%;
                position: relative;
                overflow: hidden;
                border: 0.14vw solid rgba(14, 87, 147, 1);
                border-radius: 1.4vw;
                height: 19vw;
                margin: 0 1vw;
                box-shadow: 0.3vw 0.3vw 0.6vw rgba(13, 86, 147, 0.3);
                border-top-right-radius: 2.4vw;
                background-color: white;
                .content1-left-div {
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    height: 7vw;
                    background-color: rgba(0, 0, 0, 0.5);


                    .box3-content1-p1 {
                        margin-top: 1.4vw;
                        width: 100%;
                        text-align: center;
                        color: white;
                        font-size: 1.4vw;
                        font-weight: bold;
                    }

                    .box3-content1-p2 {
                        width: 100%;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.5);
                        font-size: 1.2vw;
                    }
                }
            }
        }

        .box3-content2 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 14vw;
            margin: 3vw 0;

            .content2-div1 {
                height: 9vw;
                width: 9vw;
                border-radius: 9vw;
                margin: 0 7vw;
                background-color: rgba(14, 87, 147, 1);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                .content2-div1-img1 {
                    height: 120%;
                    position: absolute;
                }
            }
        }

        .box3-content3 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.3vw;

            .content3-left {
                width: 20vw;
                height: 9vw;
                margin: 0 0.5vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 1.9vw;
                font-weight: bold;
                color: rgba(110, 143, 191, 1);
            }
        }
    }

    .ecochain-box4 {
        width: 100%;
        background-color: rgba(249, 249, 249, 1);
        padding-top: 2.5vw;
        position: relative;
        padding-bottom: 1.4vw;

        .ecochain-box4-title {
            width: 100%;
            text-align: center;
            color: black;
            font-size: 1.3vw;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            letter-spacing: 0.1vw;
            padding-left: 8vw;
            font-weight: bold;

        }

        .box4-content1 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 1.9vw 0;

            .content1-left {
                width: 15vw;
                height: 19vw;
                margin: 0 0.4vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                .content1-left-img1 {
                    width: 100%;
                    height: 100%;
                }

                .content1-left-box1 {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.9vw;
                    font-weight: bold;
                    color: white;
                    letter-spacing: 0.5vw;
                    border-radius: 1vw;
                    background-color: rgba(0, 0, 0, 0.1);
                }

                .content1-left-box1:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }

            .content1-left1 {
                width: 5vw;
                height: 19vw;
                margin: 0 0.4vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: rgba(8, 97, 164, 1);
                border-radius: 1vw;
                color: white;
                font-weight: bold;
                font-size: 1.6vw;
            }

        }

        .box4-content2 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.7vw;
            margin-top: 2.3vw;

            .content2-left {
                width: 17vw;
                height: 8vw;
                background-color: white;
                border-radius: 1vw;
                box-shadow: 0.3vw 0.3vw 0.6vw rgba(150, 191, 225, 0.5);
                color: rgba(48, 126, 174, 1);
                font-size: 1.8vw;
                letter-spacing: 0.4vw;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        .box4-content3 {
            width: 85vw;
            height: 7.1vw;
            display: flex;
            margin: 0 auto;

            .content3-left {
                width: 25%;
                height: 85%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: bold;
                font-size: 1.7vw;
                letter-spacing: 0.23vw;
            }
        }
    }

    .ecochain-box5 {
        width: 100%;
        // padding-top: 50px;
        position: relative;
        height: 30vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .box5-content-left {
            width: 25%;

            .content-left-font1 {
                font-size: 2.7vw;
                letter-spacing: 0.14vw;
                margin-bottom: 0.4vw;
            }

            .content-left-font2 {
                display: flex;
                font-size: 1.3vw;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                letter-spacing: 0.1vw;
            }
        }
    }

    .ecochain-box6 {
        width: 100%;
        // padding-top: 50px;
        position: relative;
        // height: 30vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.9vw;

        .box6-content-left {
            width: 25vw;
            // height: 30vw;
            border-radius: 0.14vw;
            margin: 0 1.4vw;
            background-color: rgba(242, 242, 242, 1);

            // background-color: #95F204;
            .content-left-div1 {
                width: 100%;
                height: 5vw;
                background-color: rgba(51, 51, 51, 1);
                font-size: 1.2vw;
                color: rgba(255, 255, 255, 1);

                .left-div1-font1 {
                    width: 100%;
                    text-align: center;
                    padding-top: 0.6vw;
                    letter-spacing: 0.2vw;
                }

                .left-div1-font2 {
                    width: 100%;
                    font-size: 0.9vw;
                    text-align: center;
                }
            }

            .content-left-div2 {
                width: 93%;
                height: 8vw;
                display: block;
                margin: 0 auto;
                margin-top: 1vw;
                margin-bottom: 1vw;
                border-radius: 1vw;
                background-color: rgba(255, 255, 255, 0.69);
                padding: 1vw;
                font-size: 1.1vw;
                letter-spacing: 0.1vw;
                line-height: 1.5vw;
                font-weight: 500;
            }
        }
    }

    .ecochain-box7 {
        width: 100%;
        // padding-top: 50px;
        position: relative;
        // height: 30vw;

        .ecochain-box7-title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1.4vw;
            margin-top: 3.2vw;
            margin-bottom: 3.2vw;
        }

        .ecochain-box7-content1 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.4vw;
            .box7-content1-left{
                width: 12.5vw;
                height: 15.5vw;
                border: 0.1vw solid black;
                border-radius: 1.4vw;
                margin: 0 0.5vw;
                .content1-left-img1{
                    width: 90%;
                    display: block;
                    margin: 0 auto;
                    margin-top: 0.5vw;
                }
                .content1-left-font1{
                    font-size: 1.4vw;
                    text-align: center;
                    letter-spacing: 0.2vw;
                    margin-top: 0.5vw;
                }
                .content1-left-font2{
                    font-size: 0.9vw;
                    text-align: center;
                    // font-weight: bold;
                }
            }
        }
    }
}