@bodyWidth:1440px;
@textWidth: 777px;
.about-us-page{
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .item-blue-piece{
    background-color: #076CE0;
    width: 22px;
    height: 3px;
  }
  .bazaarImgCell{
    width: 100%;
    img{
      width: 100%;
    }
  }
  // 公司简介
  &>.company-profile{
    display: flex;
    flex-direction: row;
    width: @bodyWidth;
    margin-top: 80px;

    .centent{
      // flex: 1;
      margin-left: 41px;
      .title{
        color: #076CE0;
        font-weight: 700;
        font-size: 25px;
      }
      .english-title{
        color: #076CE0;
        font-size: 13px;
        margin-bottom: 25px;
      }
      .content-text{
        text-indent: 40px;
        margin-top: 50px;
        font-size: 18px;
        line-height: 42px;
        letter-spacing: 2px;
        color: #8A8A8A;
      }
    }
  }
  &>.summarize{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .title{
      margin-top: 135px;
      margin-bottom: 5px;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0;
      background-image: url("../../assets/images/组 831@2x.png");
      width: 390px;
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      text-align: center;
    }
    .english-title{
      font-size: 18px;
      font-weight: 600;
      color: #8A8A8A;
      margin-bottom: 80px;
      letter-spacing: 1;
    }
    .service-flow{

      width: @bodyWidth;
    }
    .group{
      margin-top: 80px;
      margin-bottom: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: @bodyWidth;
      img{
        width: 440px;
        height: 550px;
        // margin-bottom: 20px;
       } 
    }
    .describe{
      margin-top: 80px;
      color: #8A8A8A;
      font-size: 19px;
      letter-spacing: 1px;
      max-width: @bodyWidth;
    }
    .system-home-img{
      margin-top: 80px;
      width: 715px;
    }
  }
  section{
    width: @bodyWidth;
    background-image: url(../../assets/images/分界线.png);
    background-repeat: no-repeat;
    background-size: 100% 1px;
    // background-attachment: fixed;
    background-position: 0% 100%;
    padding: 80px 0;
    display: flex;
    height: 578px;
    // 781px -
    flex-direction: row;
    // align-items: center;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    .content-item{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .content-img{
      &>img{
        // width: 743px;
        height: 364px;

        // max-width: 624px;
      }
    }
    .item-title{
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 5px;
      .blue-title{
        color: #076CE0;
      }
    }
    .item-titleEnglish{
      font-size: 17px;
      color: #8A8A8A;
      // margin-bottom: 20px;
    }
    .item-blue-piece{
      margin-top: 20px;
      background-color: #076CE0;
      width: 22px;
      height: 3px;
    }
    .item-content{
      flex: 1;
      width: @textWidth;
      line-height: 43px;
      color: #8A8A8A;
      font-size: 16px;
      margin-top: 46px;
    }
    .icon-group{
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: @textWidth;
      img{
        width: 58px;
        height: 58px;
      }
    }
    .item-but{
      margin-top: 49px;
      width: 163px;
      height: 60px;
      border: 1px solid #0169E8;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      border-radius: 6px;
      color: #0169E8;
    }
    .icon-cell{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 97px;
    }
  }
  
  &>section:nth-of-type(1){
    // margin-top: 55px;
  }
  &>section:last-of-type{
    background-image: none;
  }
}