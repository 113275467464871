@bodyWidth:1440px;
@textWidth: 608px;
.smart-market-page{
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bazaarImgCell{
    width: 100%;
    img{
      width: 100%;
    }
  }
  section{
    width: @bodyWidth;
    background-image: url(../../assets/images/分界线.png);
    background-repeat: no-repeat;
    background-size: 100% 1px;
    // background-attachment: fixed;
    background-position: 0% 100%;
    padding: 80px 0;
    display: flex;
    height: 781px;
    // 781px -
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .content-item{
      display: flex;
      flex-direction: column;
    }
    .content-img{
      &>img{
        width: 743px;
        height: 426px;
      }
    }
    .item-title{
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .item-titleEnglish{
      font-size: 17px;
      color: #8A8A8A;
      // margin-bottom: 20px;
    }
    .item-blue-piece{
      margin-top: 20px;
      background-color: #076CE0;
      width: 22px;
      height: 3px;
    }
    .item-content{
      flex: 1;
      width: @textWidth;
      line-height: 43px;
      color: #8A8A8A;
      font-size: 16px;
      margin-top: 46px;
    }
    .icon-group{
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: @textWidth;
      img{
        width: 58px;
        height: 58px;
      }
    }
    .item-but{
      margin-top: 49px;
      width: 163px;
      height: 60px;
      // border: 1px solid #0169E8;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      border-radius: 6px;
      color: #0169E8;
      cursor: pointer;
      &>img{
        width: 100%;
        height: 100%;
      }
    }
    .icon-cell{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 97px;
    }
  }
  &>section:nth-of-type(1){
    margin-top: 55px;
  }
  &>section:last-of-type{
    background-image: none;
  }
}
