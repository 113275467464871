@bodyWidth:1440px;
@textWidth: 777px;
.audit-literature-page{
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .item-blue-piece{
    background-color: #076CE0;
    width: 22px;
    height: 3px;
  }
  .bazaarImgCell{
    width: 100%;
    img{
      width: 100%;
    }
  }
  &>.summarize{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      margin-top: 135px;
      margin-bottom: 5px;
      font-size: 26px;
      font-weight: 600;
    }
    .english-title{
      font-size: 18px;
      font-weight: 600;
      color: #8A8A8A;
      margin-bottom: 20px;
    }
    .group{
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 1080px;
      .cell{
      //  width: 80px;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
      // justify-content: space-around;
       align-items: center;
       color: #333333;
       font-size: 20px;
       .img-box{
        width: 100px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
       }
       img{
        width: 100%;
        // height: 80px;
        margin-bottom: 20px;
       } 
      }
    }
    .describe{
      width: @bodyWidth;
      margin-top: 80px;
      color: #8A8A8A;
      font-size: 18px;
      text-align: center;
    }
    .system-home-img{
      margin-top: 80px;
      width: 827px;
    }
  }
  section{
    width: @bodyWidth;
    background-image: url(../../assets/images/分界线.png);
    background-repeat: no-repeat;
    background-size: 100% 1px;
    // background-attachment: fixed;
    background-position: 0% 100%;
    padding: 80px 0;
    box-sizing: border-box;
    display: flex;
    // height: 781px;
    // 781px -
    flex-direction: column;
    // align-items: center;
    align-items:flex-start;
    justify-content: space-between;
    .content-item{
      display: flex;
      flex-direction: column;
    }
    .content-img{
      width: @bodyWidth;
      // &>img{
        // height: 364px;
      // }
    }
    .item-title{
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .item-titleEnglish{
      font-size: 17px;
      color: #8A8A8A;
      // margin-bottom: 20px;
    }
    .item-blue-piece{
      margin-top: 20px;
      background-color: #076CE0;
      width: 22px;
      height: 3px;
    }
    .item-content{
      flex: 1;
      width: @bodyWidth;
      line-height: 43px;
      color: #8A8A8A;
      font-size: 16px;
      margin-top: 46px;
    }
    .icon-group{
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: @textWidth;
      img{
        width: 58px;
        height: 58px;
      }
    }
    .item-but{
      margin-top: 49px;
      width: 163px;
      height: 60px;
      border: 1px solid #0169E8;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      border-radius: 6px;
      color: #0169E8;
    }
    .icon-cell{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 97px;
    }
  }
  // &>section:nth-of-type(1){
  //   margin-top: 55px;
  // }
  &>section:last-of-type{
    background-image: none;
  }
  // 重复部分第二项
  .item-list2{
    margin: 0 auto;
    width: 1340px;
   &>img{
    height: 622px;
   }
   &>.right{
    height: 622px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   }
   &>.right>.box{
    width: 870px;
    height: 191px;
    padding: 30px 60px;
    border: 1px solid #F1F1F1;
    // border-left: 20px solid #FFD063;
    background: linear-gradient(to right,#FFD063 1px,#FFD063 20px,#FFFFFF 20px,#FFFFFF 100%);
    // -webkit-background: linear-gradient(to right,#FFD063 0,#FFD063 20px,#FFFFFF 20px,#FFFFFF 100%);
    box-shadow: 0 6px 10px 1px rgba(000, 000, 000 ,0.16);
    border-radius: 6px;
    .title{
      color: #404040;
      font-size: 26px;
      font-weight: 700;
      // margin-bottom: 10px;
    }
    .content{
      color: #8A8A8A;
      font-size: 20px;
      font-weight: 400;
    }
   }
  }
  .item-list3{
    justify-content: space-around;
    &>.box{
      width: 354px;
      height: 456px;
      border-radius: 30px;
      box-shadow: 0 0 10px 1px rgba(000, 000, 000, 0.16);
      overflow: hidden;
      &>div:nth-of-type(1){
        height: 280px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 50px 25px 0;
        color: #FFFFFF;
        font-size: 17px;
      }
      &>div:nth-of-type(2){
        text-align: center;
        &>div:nth-of-type(1){
          margin-top: 45px;
          color: #333333;
          font-size: 24px;
        }
        &>div:nth-of-type(2){
          margin-top: 24px;
          color: #333333;
          font-size: 13px;
        }
      }
    }
  }
  .item-list4{
    justify-content: space-around;
    &>.left-bor{
      width: 384px;
      height: 578px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid #cccccc;
    }
    &>.left-bor>.left{
      background: linear-gradient(90deg,#F2BC5C,#EB7A15);
      width: 344px;
      height: 528px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #FFFFFF;
      .title{
        margin-top: 48px;
        font-size: 26px;
        font-weight: 600;
      }
      img{
        margin-top: 69px;
        width: 205px;
        height: 172px;
      }
      .wire{
        margin-top: 17px;
        color: #FFFFFF;
        background-color: #FFFFFF;
        height: 2px;
        width: 284px;
      }
      .content{
        margin-top: 30px;
        max-width: 284px;
        font-size: 18px;
        font-weight: 400;
      }
    }
    &>.right{
      width: 1026px;
      height: 578px;
      padding: 30px 20px;
      border-radius: 6px;
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
    }
    
    &>.right>.box{
      border: 1px solid #CCCCCC;
      border-radius: 6px;
      width: 986px;
      height: 120px;
      display: flex;
      flex-direction: row;
      background-repeat: no-repeat;
      background-size: 140px 120px;
      .title{
        color: #FFFFFF;
        font-size: 15px;
        width: 120px;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 17px 0;
        align-items: center;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .content{
        flex: 1;
        padding: 0 5px 0 25px;
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 17px;
        letter-spacing: 2px;
      }
      
      
    }
    &>.right>.box:nth-of-type(1){
      background-image: url("../../assets/images/联合 11@2x.png");
    }
    &>.right>.box:nth-of-type(2){
      background-image: url("../../assets/images/联合 12@2x.png");
    }
    &>.right>.box:nth-of-type(3){
      background-image: url("../../assets/images/联合 13@2x.png");
    }
    &>.right>.box:nth-of-type(4){
      background-image: url("../../assets/images/联合 14@2x.png");
    }
  }
}