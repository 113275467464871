@import '~antd/dist/antd.css';

/*.box{*/
/*    width: 1000px;*/
/*    height: 750px;*/
/*    display: inline-flex;*/
/*}*/
@font-face {
    /*自己起个名称，FontName是我起的，可修改*/
    font-family: "FontName";
    /*url引入字体路径*/
    src: url("./assets/fonts/1.TTF");
}


.globe-box{

    /*background-image: url("");*/
    /*background-color: darkseagreen;*/
}

.header{
    height: 74px;
    width: 100%;
    background-color: white;
    border-bottom: 2px solid #dddddd;
    position: fixed;
    top:0;

}

.footer{
    width: 100%; 
    height: 26vw;
    background-color: black;
}