.footer-box{
    /* padding-left: 120px; */
    padding-top: 3vw;
}

.footer-box-top{
    width: 100%;
    height: 80%;
    display: flex;
   
}
.box-top1{
    height: 100%;
    width: 18%;
    text-align: left;
}
.box-top1-font1{
    cursor: pointer;
    font-size: 1.2vw;
    color: white;
    font-weight: 500;
    margin-bottom: 1vw;
}
.box-top1-font2{
    cursor: pointer;
    font-size: 1vw;
    color: #FFFFFF ;
    opacity: 0.8;
    margin-top: 0.2vw;

}
.box-top1-font3{
    height: 4vw;
    display: flex;
    /* background-color: #FFFFFF; */
}
.top1-font4{ 
    font-size: 0.9vw;
    color: rgba(255, 255, 255, 0.8);
    margin-top: -0.3vw;
    margin-left: 0.5vw;
}
.divider-box{
    height: 2px;
    width: 94%;
    background-color: rgba(255,255,255,0.2);
    margin-top: 0.5vw;
}

.footer-bottom{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    color: rgba(255, 255, 255, 0.9);
    font-size: 1vw;
    margin-top: 1vw;
}
.footer-href{
    color:rgba(40,130,182,1);
    cursor: pointer;
}

.footer-ecochain-box{
    height: 100%;
    width: 25%;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items:baseline;  */
    
}
.ecochain-box-left{
    height: 8.5vw;
    width: 7vw; 
    background-color: #FFFFFF;
    margin: 0 1vh ;
    
}

.ecochain-box-left-line{
    width: 100%;
    height: 0.2vh;
    background-color: black;
}

.ecochain-box-left-font1{
    text-align: center;
    font-size: 1.8vh;
    font-weight: bold;

}